import React, {Component} from "react"
import {Link, graphql} from "gatsby"
import {Motion, spring} from 'react-motion'
import Layout from "../layouts"
import {Helmet} from "react-helmet";
import Social from '../components/social';

const logo = require('../images/main__logo.svg');
const img1 = require('../images/android-icon-192x192.png');
const img2 = require('../images/favicon-32x32.png');
const img3 = require('../images/favicon-96x96.png');
const img4 = require('../images/favicon-16x16.png');

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            counter: 0
        }
    }

    render() {
        const data = this.props.data

        let langs = ['pl', 'en', 'de'].map((element, index) => {

            return <li key={index}>
                {
                    element === 'pl' ?
                        <Link activeStyle={{color: "#0033cc"}} to={`/`}>{element}</Link> :
                        <Link activeStyle={{color: "#0033cc"}} to={`/${element}`}>{element}</Link>
                }

            </li>
        });

        const posts = data.allWordpressPost.edges.map((e, i) => {

            let cats = e.node.categories.map((el, ix) => {
                return <li key={ix}>{el.name}</li>
            });

            return <article className={"col-5 col-sm-12 posts__post"} key={i}>
                <div className="posts__meta">
                    <time className={"posts__time"}>{new Date(e.node.date).toLocaleDateString()}</time>
                    <ul className="posts__cats">
                        {cats}
                    </ul>
                </div>
                <Link activeStyle={{color: "#0033cc"}} className={"posts__link"} to={`/${e.node.slug}`}>
                    <h2 className={"posts__title"}
                        dangerouslySetInnerHTML={{__html: e.node.title.length > 32 ? `${e.node.title.substring(0, 32)} ...` : e.node.title}}>
                    </h2>
                </Link>

                <div className="posts__excerpt" dangerouslySetInnerHTML={{__html: e.node.excerpt}}></div>
                <Social link={typeof window !== 'undefined' ? `${window.location.origin}/${e.node.slug}` : '/'} />
            </article>
        })

        const slides = data.allWordpressWpSlider.edges.map((e, i) => {
            if (i == this.state.counter) {
                return <article key={i} className="grid slider__slide">
                    <footer className="slider__author col-1 col-sm-12"><p>{e.node.acf.author}</p></footer>

                    <Motion defaultStyle={{
                        x: 20,
                        opacity: 0
                    }} style={{
                        x: spring(0, {stiffness: 20, damping: 20}),
                        opacity: spring(1, {stiffness: 20, damping: 20})
                    }}>
                        {
                            style => (
                                <h2 style={{
                                    transform: `translateX(${style.x}px)`,
                                    opacity: style.opacity
                                }} className="slider__quote col-10 col-sm-12">{e.node.acf.quote}</h2>)
                        }

                    </Motion>
                    <p className="slider__p col-4-8 col-sm-1-12">{e.node.acf.description}</p>
                </article>
            }
        })


        return (
            <Layout>
                <Helmet>
                    <html lang="pl"/>

                    <title>Home | Kazik Koduje</title>

                    <meta name={"title"} content="welcome to internety"/>
                    <meta name={"description"} content="welcome to internety"/>
                    <meta property="og:title" content="welcome to internety"/>
                    <meta property="og:description" content="welcome to internety"/>
                    <meta property="og:url" content={typeof window !== 'undefined' ? window.location.origin : '/'}/>
                    <meta property="og:type" content="website"/>

                    <meta property="og:locale" content="pl"/>
                    <meta property="og:image" content={logo}/>

                    <link rel="icon" type="image/png" sizes="192x192" href={img1}/>
                    <link rel="icon" type="image/png" sizes="32x32" href={img2}/>
                    <link rel="icon" type="image/png" sizes="96x96" href={img3}/>
                    <link rel="icon" type="image/png" sizes="16x16" href={img4}/>
                    <meta name="msapplication-TileColor" content="#ffffff"/>
                    <meta name="theme-color" content="#ffffff"/>

                    {/*<meta name="twitter:card" content="summary" />*/}
                    {/*<meta name="twitter:site" content={twitter} />*/}
                </Helmet>
                {/* <div className="grid">
                    <ul className="langs col-12">
                        {langs}
                    </ul>
                </div> */}
                <section className="slider">
                    {slides}
                </section>
                <section className="posts grid">
                    {posts}
                </section>
            </Layout>
        )
    }

    componentDidMount() {
        this.int = setInterval(() => {
            this.setState({
                counter: this.props.data.allWordpressWpSlider.edges.length ? (this.state.counter + 1) % this.props.data.allWordpressWpSlider.edges.length : 0
            })
        }, 7000)
    }
}

export default Home

// Set here the ID of the home page.
export const pageQuery = graphql`
    query {
        allWordpressWpSlider {
            edges {
                node {
                    content
                    acf {
                        author
                        quote
                        description
                    }
                }
            }
        }
        allWordpressPost {
            edges {
                node {
                    date
                    title
                    excerpt
                    slug
                    categories {
                        id
                        name
                        link
                    }
                }
            }
        }
    }
`
